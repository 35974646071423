import React, { useState, useEffect } from "react";
import style10 from "./Home.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faLeaf,
  faMicrochip,
  faLayerGroup,
  faLightbulb,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import L from "./Images/lift4.jpg";
import L1 from "./Images/lift2.jpg";
import k from "./Images/k.jpg";
import k1 from "./Images/k1.jpg";
import k2 from "./Images/k2.jpg";
import k3 from "./Images/k3.jpg";
import S from "./Images/sec1.jpg";
import S1 from "./Images/tre.jpg";
import S2 from "./Images/tre1.jpg";
import S3 from "./Images/tre2.jpg";
import S4 from "./Images/tre3.jpg";

const liftImages = [L, L1, k, k1, k2, k3];
const liftCaptions = [
  "Modern Operations",
  "Technology",
  "Modern business hall lifts",
  "Office corridor with elevator during morning rush",
  "Escalators",
  "Three elevators in hotel lobby",
];

const main3Images = [S1, S2, S3, S4];

function Home() {
  const [currentLiftIndex, setCurrentLiftIndex] = useState(0);
  const [currentMain3Index, setCurrentMain3Index] = useState(0);

  const nextLiftImage = () => {
    setCurrentLiftIndex((prevIndex) => (prevIndex + 1) % liftImages.length);
  };

  const prevLiftImage = () => {
    setCurrentLiftIndex(
      (prevIndex) => (prevIndex - 1 + liftImages.length) % liftImages.length
    );
  };

  useEffect(() => {
    const liftIntervalId = setInterval(nextLiftImage, 3000);
    return () => clearInterval(liftIntervalId);
  }, []);

  const nextMain3Image = () => {
    setCurrentMain3Index((prevIndex) => (prevIndex + 1) % main3Images.length);
  };

  useEffect(() => {
    const main3IntervalId = setInterval(nextMain3Image, 4000);
    return () => clearInterval(main3IntervalId);
  }, []);

  return (
    <div className={style10.Liftcontainer}>
      <div className={style10.Liftwrapper}>
        <img
          src={liftImages[currentLiftIndex]}
          alt={`lift${currentLiftIndex + 1}`}
          loading="lazy"
        />
        <div className={style10.controls}>
          <button onClick={prevLiftImage} className={style10.controlbuttonleft}>
            ←
          </button>
          <span className={style10.imagenumber}>
            {currentLiftIndex + 1} / {liftImages.length}
          </span>
          <button
            onClick={nextLiftImage}
            className={style10.controlbuttonright}
          >
            →
          </button>
        </div>
        <div className={style10.caption}>{liftCaptions[currentLiftIndex]}</div>
      </div>

      <div className={style10.Qualitytext}>QUALITY IS OUR CORNERSTONE</div>
      <div className={style10.text1}>Welcome To</div>
      <div className={style10.text2}>Atlantic Lifts India Pvt Ltd</div>

      <div className={style10.sec}>
        <div className={style10.sec1}>
          <img src={S} alt="Sec 1 Img" loading="lazy" />
        </div>
        <div className={style10.sec2}>
          <div className={style10.sec21}>Established 2015</div>
          <div className={style10.sec22}>
            Elevator Manufacturer based in Pune
          </div>
          <div className={style10.sec23}>
            Atlantic Lifts offers all types of vertical transit services
            including passenger lifts and freight lifts. We offer installation
            and maintenance of elevators and escalators and have the most
            technologically advanced products engineered to cater customer
            requirements to perfection. At Atlantic Lifts we urge you to rethink
            your transit systems; Is your electricity bill shooting up? Do they
            have hidden repair costs? Do they not fit in well with your property
            design? If the answer is yes, contact us.
          </div>
          <div className={style10.sec24}>
            <NavLink to="/about">
              Know More &gt;
            </NavLink>
          </div>
        </div>
      </div>

      <div className={style10.main3}>
        <img
          src={main3Images[currentMain3Index]}
          alt={`tre${currentMain3Index + 1} Img`}
          loading="lazy"
        />
        <div className={style10.main3textbox}>
          <div className={style10.tre11}>QUALITY IS OUR CORNERSTONE​</div>
          <div className={style10.tre12}>
            Atlantic Lifts is a leading provider of elevators and escalators.
            Quality is the foundation of our business and we strive to achieve
            market leadership through superior products and exceptional service.
            Our customers trust us because our output is always the result of
            engineered excellence along with perseverance in delivering our
            commitments. Some of the ways in which we ensure this are: Full
            transparency in processes Integrity in product commitments Hassle
            free and responsive approach
          </div>
          <div className={style10.tre13}>
            <NavLink to="/Gallery">
              Gallery &gt;
            </NavLink>
          </div>
        </div>
      </div>
      <div className={style10.slast}>
        <div className={style10.last1}>Technology Is Our Wisdom</div>
        <div className={style10.ls1}>Technology Is Our Wisdom</div>
        <div className={style10.ls2}>Why Choose Us</div>
        <div className={style10.iconscontainer}>
          <div className={style10.icongroup}>
            <FontAwesomeIcon icon={faCalendarCheck} />
            <div className={style10.icontext}>Safety Compliance</div>
            <div className={style10.subtext}>EN 81:1 Safety Compliance</div>
          </div>
          <div className={style10.icongroup}>
            <FontAwesomeIcon icon={faLeaf} />
            <div className={style10.icontext}>Eco Friendly Design</div>
            <div className={style10.subtext}>
              No hazardous material for environment
            </div>
          </div>
          <div className={style10.icongroup}>
            <FontAwesomeIcon icon={faMicrochip} />
            <div className={style10.icontext}>Smoother Technology</div>
            <div className={style10.subtext}>
              Comfort and Smoother Technology
            </div>
          </div>

          <div className={style10.icongroup}>
            <FontAwesomeIcon icon={faLayerGroup} />
            <div className={style10.icontext}>Customized Solutions</div>
            <div className={style10.subtext}>
              We do offer customized solutions
            </div>
          </div>

          <div className={style10.icongroup}>
            <FontAwesomeIcon icon={faLightbulb} />
            <div className={style10.icontext}>Energy Efficient Elevators</div>
            <div className={style10.subtext}>
              Less power consumption elevator
            </div>
          </div>

          <div className={style10.icongroup}>
            <FontAwesomeIcon icon={faShieldAlt} />
            <div className={style10.icontext}>Automatic Rescue Operation</div>
            <div className={style10.subtext}>
              Effective in emergency situation
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
