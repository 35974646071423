import React from "react";
import style3 from "./Carrer.module.css";
import img from "./Images/tre3.jpg";
import img1 from "./Images/Carrer1_enhanced.webp";

const Carrer = () => {
  return (
    <div className={style3.mainconatiner}>
      <div className={style3.imagecontainer}>
        <img src={img} alt=""></img>
        <div className={style3.textoverlay}>
          <h2>CAREERS</h2>
          <h6 className={style3.fo}>Always looking for enthusiastic Candidate</h6>
        </div>
      </div>

      <h2 className={style3.textheading}>CAREERS</h2>
      <div className={style3.cardcontainer}>
        <div className={style3.qualitypolicycard}>
          <div className={style3.qualityinfo}>
            <div className={style3.card}>
              <div className={style3.content}>
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"></path>
                </svg>
                <p className={style3.para}>
                  We at ATLANTIC LIFT believe talent has no limits we want
                  people to succeed and get the most from their careers .We
                  believe that performance of the product directly improves by
                  engaging and developing our people. Personal growth is
                  strongly supported and there are versatile opportunities for
                  career development. And at ATLANTIC LIFT, we practice what we
                  preach—providing an environment that is fast-paced, supportive
                  and empowering. We encourage individuals to take ownership of
                  their day-to-day work experience, as well as their future
                  career path. So if you believe in working hard and playing
                  hard, you belong here. Interested to work with us? Mail your
                  resume to <a href="hr@atlanticlift.in">hr@atlanticlift.in</a>
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className={style3.teampolicycard}>
          <div className={style3.teamimage}>
            <img src={img1} alt="Team Policy" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrer;
