import React, { useState } from "react";
import style13 from "./Mashin.module.css";
import Desc from "./Images//greentek_enhanced.png";
import g1 from "./Images/1.jpg";
import g8 from "./Images/8.jpg";
import g9 from "./Images/9.jpg";
import g10 from "./Images/10.jpg";
import g12 from "./Images/12.jpg";
import g13 from "./Images/13.jpg";
import g15 from "./Images/15.jpg";
import g16 from "./Images/16.jpg";
import g17 from "./Images/17.jpg";
import g19 from "./Images/19.jpg";
import g20 from "./Images/20.jpg";
import g21 from "./Images/21.jpg";
import g22 from "./Images/22.jpg";
import g23 from "./Images/23.jpg";
import g24 from "./Images/24.jpg";
import g25 from "./Images/25.jpg";
import g26 from "./Images/26.jpg";
import g27 from "./Images/27.jpg";
import g28 from "./Images/28.jpg";
import g29 from "./Images/29.jpg";
import g30 from "./Images/30.jpg";
import g31 from "./Images/31.jpg";
import g32 from "./Images/32.jpg";
import g33 from "./Images/33.jpg";
import g34 from "./Images/34.jpg";
import g35 from "./Images/35.jpg";
import g38 from "./Images/38.jpg";
import g39 from "./Images/39.jpg";
import g40 from "./Images/40.jpg";
import g41 from "./Images/41.jpg";

const images = [
  g1,
  g8,
  g9,
  g10,
  g12,
  g13,
  g15,
  g16,
  g17,
  g19,
  g20,
  g21,
  g22,
  g23,
  g24,
  g25,
  g26,
  g27,
  g28,
  g29,
  g30,
  g31,
  g32,
  g33,
  g34,
  g35,
  g38,
  g39,
  g40,
  g41,
];

const Mashin = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const showNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPrev = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className={style13.mainconatiner}>
      <div className={style13.imagecontainer}>
        <img src={Desc} alt=""></img>
        <div className={style13.textoverlay}>
          <h2>Machine</h2>
          <h6 className={style13.fo}>We have soltions for all !!</h6>
        </div>
      </div>

      <h2 className={style13.textheading}>Machine</h2>

      <div className={style13.gallerygrid}>
        {images.map((img, index) => (
          <div
            className={style13.galleryitem}
            key={index}
            onClick={() => openModal(index)}
          >
            <img src={img} alt={`Gallery Image ${index + 1}`} />
          </div>
        ))}
      </div>

      {selectedImageIndex !== null && (
        <div className={style13.modal} onClick={closeModal}>
          <span className={style13.close} onClick={closeModal}>
            &times;
          </span>
          <div
            className={style13.modalcontent}
            onClick={(e) => e.stopPropagation()}
          >
            <img src={images[selectedImageIndex]} alt="Selected" />
            <button className={style13.prev} onClick={showPrev}>
              &#10094;
            </button>
            <button className={style13.next} onClick={showNext}>
              &#10095;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mashin;
