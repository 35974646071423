import React from 'react';
import './Contact.css';
import fin from './Images/855191-hd_1920_1080_25fps.mp4';
const Contact = () => {
  return (
    <div className='main-conatiner'>
    <div className='image-container'>
        <video  autoPlay loop muted>
            <source src={fin} type='video/mp4' />
        </video>
    <div className='text-overlay'>
        <h2>Reach Out</h2>
    <h6 className='fo'>We Love To Here From you!!</h6>
    </div>
    </div>
    <div className='heading'><h2>Head Office</h2></div>
     <div className="card-container">
        <div className="card"> 
        <div className="card-info">
  <ul>
    <li>
    <i className="fas fa-map-marker-alt"></i>Address: <span>Manik Moti Complex D-2,1st Floor Katraj,
       Pune-411046 State: Maharastra</span>
    </li>
    <li>
    <i className="fas fa-envelope"></i>Email us: <span>customercare@atlanticlifts.in
      
       sales@atlanticlifts.in</span>
    </li>
    <li>
    <i className="fas fa-phone-alt"></i>Call us: <span><ul><li>(020) 243 78 895</li>
                    <li> 91588 78899</li>
                     <li>90086 29008</li></ul></span>
    </li>
  </ul>
</div>


        </div>
        <div className="card"> 
          <div className="map">
          <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15139.021926017682!2d73.8394625554199!3d18.44940800000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eac4cd05480b%3A0x4d564b47537bfbf4!2sAtlantic%20Lifts%20India%20Pvt.%20Ltd!5e0!3m2!1sen!2sus!4v1728452900966!5m2!1sen!2sus"
        className="map-frame"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Atlantic Lifts Location"
      ></iframe>
          </div>
        </div>
      </div>

      <div className='heading'><h2>Branch Offices</h2></div>
      <div className="card-container1">
        <div className="card"> 
            <h3 className='head'>Solapur</h3>
        <div className="card-info">
  <ul>
    <li>
    <i className="fas fa-map-marker-alt"></i>Address: <span>Ravi Arcade, Near Manorama Hospital, Dawat Hotel Chowk, Jule, Solapur</span><span>State: Maharashtra</span>
    </li>
    <li>
    <i className="fas fa-phone-alt"></i>Call us: <span><ul><li>77559 90367 </li>
                    <li>70386 62105​</li>
                     </ul></span>
    </li>
  </ul>
</div>
</div>
<div className="card"> 
    <h3 className='head'>Latur</h3>
        <div className="card-info">
  <ul>
    <li>
    <i className="fas fa-map-marker-alt"></i>Address: <span>Gangasagar Apartment, Behind Kalika Temple, Sadguru nagar, Latur</span><span>State: Maharashtra</span>
    </li>
    <li>
    <i className="fas fa-phone-alt"></i><br></br>Call us: <span><ul><li>98508 45855</li>
                    <li>90086 29008</li>
                     </ul></span>
    </li>
  </ul>
</div>

        </div>
        <div className="card"> 
            <h3 className='head'>Basavakalyan</h3>
        <div className="card-info">
  <ul>
    <li>
    <i className="fas fa-map-marker-alt"></i>Address: <span>Near Hotel Rajkamal, Tirupurant, basavakalyan, Dist: Bidar</span><span>State: Karanataka</span>
    </li>
    <li>
    <i className="fas fa-phone-alt"></i><br></br>Call us: <span><ul><li>9008629008</li>
      <li>90086 29008</li>
                     </ul></span>
    </li>
  </ul>
</div>


        </div>


        </div>
    </div>
  )
}

export default Contact