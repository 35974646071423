import React, { useState } from "react";
import axios from "axios";
import style4 from "./ChatBot.module.css";

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false); // State to track chat open/close
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    try {
      const response = await axios.post("http://localhost:3001/submit", {
        name,
        email,
        phone,
        enquiry,
      });
      console.log(response.data.message);
      setSubmitted(true);
      setError(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      setError(true);
    }
  };

  return (
    <div className={style4.chatcontainer}>
      {isOpen ? (
        <div className={style4.chatbox}>
          <div className={style4.chatheader}>Virtual Agent</div>
          <div className={style4.chatbody}>
            {!submitted ? (
              <div>
                <p>
                  Hello, Welcome to Virtual Agent! How may I help you today?
                </p>
                <p>
                  We would love to talk to you. But first, please tell us a bit
                  about yourself.
                </p>

                <label>
                  <p>Please help me with your name:</p>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </label>

                <label>
                  <p>To help you in a better way, may I know your email?</p>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>

                <label>
                  <p>
                    Just to complete this request, may I please have your mobile
                    number as an alternate contact?
                  </p>
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </label>

                <label>
                  <p>Please enter your product-related enquiry below:</p>
                  <select
                    value={enquiry}
                    onChange={(e) => setEnquiry(e.target.value)}
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="ATLANTIC - OPTIMA">ATLANTIC - OPTIMA</option>
                    <option value="ATLANTIC - OPTIMA PLUS">
                      ATLANTIC - OPTIMA PLUS
                    </option>
                    <option value="ATLANTIC - CARE">ATLANTIC - CARE</option>
                    <option value="ATLANTIC - CARRIER">
                      ATLANTIC - CARRIER
                    </option>
                    <option value="ATLANTIC - HOME LIFT">
                      ATLANTIC - HOME LIFT
                    </option>
                    <option value="ATLANTIC - COMPACT">
                      ATLANTIC - COMPACT
                    </option>
                    <option value="ATLANTIC - MANUAL LIFT">
                      ATLANTIC - MANUAL LIFT
                    </option>
                    <option value="Special">Special</option>
                  </select>
                </label>

                <button onClick={handleSubmit}>Submit</button>
              </div>
            ) : (
              <p>Thank you! Your response has been submitted.</p>
            )}
          </div>
          <button className={style4.closebtn} onClick={() => setIsOpen(false)}>
            X
          </button>
        </div>
      ) : (
        <div className={style4.chatbutton} onClick={() => setIsOpen(true)}>
          <div className={style4.chaticon}>💬</div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
