import React from "react";
import style14 from "./Modernization.module.css";
import h from "../src/Images/mod11.jpeg";
import j from "../src/Images/mod33.jpeg";
import p from "../src/Images/mod22.jpeg";
import y from "../src/Images/mod44.jpeg";

const Modernization = () => {
  return (
    <div>
      <div className={style14.mainModern}>
        <div className={style14.textModern}>
          <h3 className={style14.h3Modern}>Modernization</h3>
          <h5 className={style14.soModern}>We Have Solutions for All!</h5>
        </div>
      </div>
      <div className={style14.secModern}>MODERNIZATION</div>
      <div className={style14.sec1Modern}>
        One of your concerns when it comes to modernizing or replacing your lift is the discomfort to users. We at Atlantic make a point that customer comfort comes first. We make sure that the work is done in a way that the customer is always satisfied, and the work is always on time.
      </div>
      <div className={style14.sec2Modern}>
        ☆ We believe in doing things always before time, and perfection in the service matters the most at Atlantic Lift
        <br />☆ Low price spares and parts supply, annual safety inspection, regular and comprehensive quality return visits make Atlantic Lift superior to the rest.
      </div>
      <div className={style14.sec3Modern}>Advantages of MODERNIZATION</div>
      <div className={style14.features}>
        <div className={style14.feature}>
          <div className={style14.featureTitle}>ECO FRIENDLY DESIGN</div>
        </div>
        <div className={style14.feature1}>
          <div className={style14.featureTitle}>LESS POWER CONSUMPTION</div>
        </div>
        <div className={style14.feature2}>
          <div className={style14.featureTitle}>EN 81:1 SAFETY COMPLIANCE</div>
        </div>
        <div className={style14.feature3}>
          <div className={style14.featureTitle}>ECO GREEN</div>
        </div>
        <div className={style14.feature4}>
          <div className={style14.featureTitle}>AUTOMATIC RESCUE OPERATION DEVICE</div>
        </div>
        <div className={style14.feature5}>
          <div className={style14.featureTitle}>ZERO MAINTENANCE SYSTEM</div>
        </div>
        <div className={style14.feature6}>
          <div className={style14.featureTitle}>COMFORT AND SMOOTHER TECHNOLOGY</div>
        </div>
      </div>

      {/* Image Grid - Below Content */}
      <div className={style14.imageGrid}>
        <img
          src={h}
          alt="Modernization 1"
          className={style14.modernizationImage}
        />
        <img
          src={j}
          alt="Modernization 2"
          className={style14.modernizationImage}
        />
        <img
          src={p}
          alt="Modernization 3"
          className={style14.modernizationImage}
        />
        <img
          src={y}
          alt="Modernization 4"
          className={style14.modernizationImage}
        />
      </div>
    </div>
  );
};

export default Modernization;
