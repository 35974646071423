import React, { useState } from 'react';
import style8 from './Gallery.module.css'
import Desc from './Images//greentek_enhanced.png'
import g2 from './Images/2.jpg';
import g3 from './Images/3.jpg';
import g4 from './Images/4.jpg';
import g5 from './Images/5.jpg';
import g6 from './Images/6.jpg';
import g7 from './Images/7.jpg';
import g11 from './Images/11.jpg';
import g18 from './Images/18.jpg';
import g36 from './Images/36.jpg';
import g37 from './Images/37.jpg';
import g42 from './Images/42.jpg';
import g43 from './Images/43.jpg';
import g44 from './Images/44.jpg';
import g45 from './Images/45.jpg';
import g46 from './Images/46.jpg';
import g47 from './Images/47.jpg';
import g48 from './Images/48.jpg';
import g49 from './Images/49.jpg';




const images = [
    g2, g3, g4, g5, g6, g7,
  g11, g18, g36, g37, g42, g43, g44, g45, g46, g47, g48, g49, g2, g3
  ];
  
  const Gallery = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  
    const openModal = (index) => {
      setSelectedImageIndex(index);
    };
  
    const closeModal = () => {
      setSelectedImageIndex(null);
    };
  
    const showNext = () => {
      setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
  
    const showPrev = () => {
      setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
  

  
  return (
    <div className={style8.mainconatiner}>
        <div className={style8.imagecontainer}>
            <img src={Desc} alt=''></img>
        <div className={style8.textoverlay}>
            <h2>Photos</h2>
        <h6 className={style8.fo}>We have soltions for all !!</h6>
        </div>
        </div>

         <h2 className={style8.textheading}>Photos</h2>
       
         <div className={style8.gallerygrid}>
          {images.map((img, index) => (
            <div className={style8.galleryitem} key={index} onClick={() => openModal(index)}>
              <img src={img} alt={`Gallery Image ${index + 1}`} />
            </div>
          ))}
        </div>

        {selectedImageIndex !== null && (
          <div className={style8.modal} onClick={closeModal}>
            <span className={style8.close} onClick={closeModal}>&times;</span>
            <div className={style8.modalcontent} onClick={(e) => e.stopPropagation()}>
              <img src={images[selectedImageIndex]} alt="Selected" />
              <button className={style8.prev} onClick={showPrev}>&#10094;</button>
              <button className={style8.next} onClick={showNext}>&#10095;</button>
            </div>
          </div>
        )}
    </div>
  )
}

export default Gallery