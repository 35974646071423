import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style16 from "./start.module.css"; // Import the separated CSS

function Start({ setShowNavbar }) {
  const [showStartScreen, setShowStartScreen] = useState(true);
  const [fadeOut, setFadeOut] = useState(false); // State to control fade-out effect
  const [showBlackBackground, setShowBlackBackground] = useState(false); // New state for black background
  const navigate = useNavigate();

  useEffect(() => {
    // Hide the navbar while the start screen is shown
    setShowNavbar(false);

    const timer1 = setTimeout(() => {
      setFadeOut(true); // Start fade-out effect after 6 seconds
    }, 7000); // Adjust the timing for fade-out effect

    const timer2 = setTimeout(() => {
      setShowStartScreen(false); // Hide the start screen
      setShowBlackBackground(true); // Show the black background
      setTimeout(() => {
        setShowBlackBackground(false); // Hide the black background after 1 second
        navigate("/Formmain"); // Navigate to home after black background
      }, 200); // Adjust timing for black background visibility
    }, 5000); // Navigate after 10 seconds

    // Clean up the timers when the component unmounts
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      setShowNavbar(true);
    };
  }, [navigate, setShowNavbar]);

  return (
    <>
      {showStartScreen && (
        <div className={`start-screen ${fadeOut ? "fade-out" : ""}`}>
          <video
            src={require("./Images/lift.mp4")}
            autoPlay
            muted
            loop
            className={style16.startvideo}
          />
          <div className={style16.textoverlay}>
            <a className={style16.welcometext}>
              Welcome to
              <br />
            </a>
            <a className={style16.companyname}>
              Atlantic Lifts India PVT.LTD <br />
            </a>
            <a className={style16.tagline}>
              Technology is our wisdom <br />
            </a>
          </div>
        </div>
      )}
      {showBlackBackground && <div className={style16.blackbackground} />}{" "}
      {/* Black background */}
    </>
  );
}

export default Start;
