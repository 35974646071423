import style1 from "./About.module.css";
import aboutImage from "./Images/about-1.png";
import aboutImag from "./Images/about-2.png";
import aboutIma from "./Images/about-3.jpg";
import aboutIm from "./Images/atlantic_enhanced.jpg";

const About = () => {
  return (
    <div className={style1.aboutcontainer}>
      <div className={style1.aboutimage}>
        <img src={aboutIm} alt="About Us" />
        <div className={style1.abouttext}>
          <h6>A FEW WORDS ABOUT </h6>
          <h2 className={style1.lift}>ATLANTIC LIFTS</h2>
        </div>
      </div>
      <div className={style1.fgt}>
        <h1 className={style1.aboutheading}>ABOUT US</h1>

        <p className={style1.aboutinfo}>
          Atlantic Lift India Pvt. Ltd. has been established in the year 2015
          with the main objective to provide extremely good quality systems with
          EN-81 & ASMI-17 safety norms. Atlantic now practices exclusively in
          providing valued clients all over India with class-leading elevator
          systems. The company believes in quality & safety with “ZERO
          TOLERANCE” for products and employee safety. Atlantic offers supreme
          quality elevators with crafted design, handy technology, reliability,
          and safeguards for human and material transference. The company
          remains engrossed in progress, chasing finesse, and brilliance in
          invention with a sincere mission to achieve total customer
          satisfaction.
        </p>
        <h2 className={style1.teamheading}>OUR TEAM</h2>
        <div className={style1.teamsection}>
          <div className={style1.teampolicycard}>
            <div className={style1.teamimage2}>
              <img src={aboutImage} alt="PREMNATH SHRINAME" />
            </div>
            <div className={style1.teaminfo}>
              <h3>PREMNATH SHRINAME</h3>
              <p>
                Premnath is an electrical engineer by profession with more than
                20 years of experience in the “ELEVATOR” industry and has also
                worked with MNC companies, focusing on continuous improvement in
                product quality and customer satisfaction.
              </p>
            </div>
          </div>

          <div className={style1.teampolicycard}>
            <div className={style1.teamimage1}>
              <img src={aboutImag} alt="JITENDRA MARATHE" />
            </div>
            <div className={style1.teaminfo}>
              <h3>JITENDRA MARATHE</h3>
              <p>
                Jitendra is an electrical engineer by profession having 18 years
                of experience in the Petrochemical industry, monitoring product
                and employee safety.
              </p>
            </div>
          </div>
        </div>

        <h2 className={style1.qualityheading}>QUALITY POLICY</h2>
        <div className={style1.qualitypolicycard}>
          <div className={style1.qualityimage}>
            <img src={aboutIma} alt="Quality Policy" />
          </div>
          <div className={style1.qualityinfo}>
            <p>
              At Atlantic Lift, quality makes demand in marketplace lifts and
              elevators modernize and maintain elevators, escalators, the heart
              of our business is problem solving. Our quality and relationship
              with our existing and new customer for both we support 24×7. We
              are dedicated to providing the safest, most reliable solutions
              possible.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
