import style11 from "./Home1.module.css";
import L from "./Images/homevid2.mp4";
import Gallery from "./Gallery";
import Modernization from "./Modernization";
import React, { useState, useEffect } from "react";

function Home() {
  // Define counters with their max values and speeds
  const counters = [
    { maxCount: 800, speed: 10, label: "Installations" },
    { maxCount: 85, speed: 30, label: "Experienced Team" },
    { maxCount: 15, speed: 50, label: "Years Experience" },
    { maxCount: 7, speed: 100, label: "Products" },
  ];

  // Initialize state to track each counter's current count
  const [counts, setCounts] = useState(counters.map(() => 1));

  // Set up each counter's interval based on its speed
  useEffect(() => {
    const intervals = counters.map((counter, i) => {
      if (counts[i] < counter.maxCount) {
        return setInterval(() => {
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[i] += 1;
            return newCounts;
          });
        }, counter.speed);
      }
      return null;
    });

    // Clean up intervals on component unmount or update
    return () => intervals.forEach((interval) => clearInterval(interval));
  }, [counts]);
  return (
    <div>
      <div className={style11.Liftcontainer}>
        <div className={style11.Liftwrapper}>
          <video
            className={style11.Liftvideo}
            style={{ width: `100rem` }} // Dynamically adjust size
            src={L}
            alt="Lift Video"
            autoPlay
            loop
            muted
          />
          <div className={style11.caption}>Modern Elevators in Action</div>
        </div>
      </div>
      <div className={style11.cardmain}>
        <div className={style11.card}>
          <p className={style11.cardtitle}>Installation</p>
          <p className={style11.smalldesc}>
            We offer a range of elevator installation services tailored to meet
            the unique needs of our clients. Our team works closely with
            architects, builders, and property owners to design and install
            elevators that meet their specific requirements. We provide both new
            installations and modernizations of existing systems.
          </p>
          <div className={style11.gocorner}>
            <div className={style11.goarrow}>→</div>
          </div>
        </div>
        <div className={style11.card}>
          <p className={style11.cardtitle}>Maintenance</p>
          <p className={style11.smalldesc}>
            Regular maintenance is essential to ensure the safe and efficient
            operation of your elevators. Our maintenance plans are tailored to
            meet the unique needs of each customer, including routine
            inspections, lubrication, and testing. We also offer 24/7 emergency
            maintenance services to ensure that your elevators are always
            operational.
          </p>
          <div className={style11.gocorner}>
            <div className={style11.goarrow}>→</div>
          </div>
        </div>
        <div className={style11.card}>
          <p className={style11.cardtitle}>Repair</p>
          <p className={style11.smalldesc}>
            When your elevators experience a breakdown, you need a quick and
            efficient solution. Our team of experienced technicians is available
            around the clock to diagnose and repair any issues that arise. We
            keep a wide range of replacement parts in stock to ensure that we
            can fix any problem as quickly as possible.
          </p>
          <div className={style11.gocorner}>
            <div className={style11.goarrow}>→</div>
          </div>
        </div>
      </div>
      <div className={style11.custom_card}>
        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={style11.custom_card__icon}
        >
          <path d="M20 5H4V19L13.2923 9.70649C13.6828 9.31595 14.3159 9.31591 14.7065 9.70641L20 15.0104V5ZM2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"></path>
        </svg>
        <div className={style11.custom_card__content}>
          <p className={style11.custom_card__title}>FACTS</p>
          <div className={style11.pmain}>
            <div className={style11.pmain1}>
              <p className={style11.custom_card__description1}>
                With our knowledge we guarantee success
              </p>
            </div>
            <div className={style11.pmain2}>
              <p className={style11.custom_card__description}>
                As technology advances, committed to harnessing the collective
                benefits of advanced and eco-friendly technology. We strive to
                constantly improve and elevate our services to become one of the
                top elevator companies. Our team is dedicated to providing
                exceptional quality and customer satisfaction, ensuring that
                every project is delivered with the highest standards of
                excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={style11.countmain}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {counters.map((counter, i) => (
            <div key={counter.label} style={{ textAlign: "center" }}>
              <h2>{counts[i]} +</h2>
              <p>{counter.label}</p>
            </div>
          ))}
        </div>
      </div>
      <Gallery />
      <Modernization />
      
    </div>
  );
}

export default Home;
