import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import About from './About';
import Home from './Home';
import Home1 from './Home1';
import Navbar from './Navbar';
import Footer from './Footer';
import Start from './start';  
import Gallery from './Gallery';
import Modernization from './Modernization';
import Lifts from './Lifts';
import ChatBot from './ChatBot';
import Career from './Carrer';
import Contact from './Contact';
import FormComponent from './Formmain';
import Help from './Help';
import Apply from './Apply';
import Mashin from './Mashin';

function App() {
  const [showNavbar, setShowNavbar] = useState(true);

  return (
    
    <Router>
      {showNavbar && <Navbar />}
      <Routes>
        <Route path="/" element={<Start setShowNavbar={setShowNavbar} />} />
        <Route path="/home1" element={<Home1 />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Modernization" element={<Modernization />} />
        <Route path="/Lifts" element={<Lifts />} />
        <Route path="/Carrer" element={<Career />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Help" element={<Help />} />
        <Route path="/Apply" element={<Apply />} />
        <Route path="/Mashin" element={<Mashin />} />
        
        {/* Pass setShowNavbar to FormComponent */}
        <Route path="/Formmain" element={<FormComponent setShowNavbar={setShowNavbar} />} />
      </Routes>
      
      {showNavbar && <ChatBot />}
      {showNavbar && <Footer />}
    </Router>
  );
}

export default App;
