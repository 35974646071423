import React from "react";
import style12 from "./Lifts.module.css";
import LIIM1 from "../src/Images/LI1.png";
import LIIM2 from "../src/Images/LI2.png";
import LIIM3 from "../src/Images/LI3.jpg";
import LIIM4 from "../src/Images/LI4.png";
import LIIM5 from "../src/Images/LI5.jpg";
import LIIM6 from "../src/Images/LI6.png";
import LIIM7 from "../src/Images/LI7.jpg";
import LIIM11 from "../src/Images/LI11.jpg";
import LIIM12 from "../src/Images/LI12.png";
import LIIM13 from "../src/Images/LI13.jpg";
import LIIM14 from "../src/Images/LI14.png";
import LIIM15 from "../src/Images/LI15.jpg";

const Lifts = () => {
  return (
    <div>
      <div className={style12.mainlift}>
        <div className={style12.textlift}>
          <h3 className={style12.h3lift}>Products</h3>
          <h5 className={style12.solift}>We Have Solutions for All!</h5>
        </div>
      </div>
      <div className={style12.ml}>
        <div className={style12.ml1}>ATLANTIC - OPTIMA</div>
      </div>
      <div className={style12.overlaybox}>
        <div className={style12.box}>
          <h4>ATLANTIC - OPTIMA</h4>
          <ul>
            <li>☆ Automatic technology with geared machine</li>
            <li>☆ Best ride comfort</li>
            <li>☆ 2 hours fire-rated center opening doors</li>
            <li>☆ Full beam safety curtain</li>
            <li>☆ EN 81:1 safety compliance</li>
            <li>☆ Stainless steel and M.S. Powder coated cabins</li>
          </ul>
        </div>
        <div className={style12.box}>
          <img src={LIIM1} alt="LIIM1" className={style12.boximage} />
        </div>
      </div>
      <div className={style12.ml}>
        <div className={style12.ml1}>ATLANTIC - OPTIMA PLUS</div>
      </div>
      <div className={style12.overlaybox}>
        <div className={style12.box}>
          <h4>ATLANTIC - OPTIMA PLUS</h4>
          <ul>
            <li>☆ Automatic technology with geared machine</li>
            <li>☆ Best ride comfort</li>
            <li>☆ 2 hours fire-rated center opening doors</li>
            <li>☆ Full beam safety curtain</li>
            <li>☆ EN 81:1 safety compliance</li>
            <li>☆ Stainless steel and M.S. Powder coated cabins</li>
          </ul>
        </div>
        <div className={style12.box}>
          <img src={LIIM2} alt="LIIM1" className={style12.boximage} />
        </div>
      </div>
      <div className={style12.ml}>
        <div className={style12.ml1}>ATLANTIC - CARE</div>
      </div>
      <div className={style12.overlaybox}>
        <div className={style12.box}>
          <h4>ATLANTIC - CARE</h4>
          <ul>
            <li>☆ Automatic technology with geared machine</li>
            <li>☆ Best ride comfort</li>
            <li>☆ 2 hours fire-rated center opening doors</li>
            <li>☆ Full beam safety curtain</li>
            <li>☆ EN 81:1 safety compliance</li>
            <li>☆ Stainless steel and M.S. Powder coated cabins</li>
          </ul>
        </div>
        <div className={style12.box}>
          <img src={LIIM3} alt="LIIM1" className={style12.boximage} />
        </div>
      </div>
      <div className={style12.ml}>
        <div className={style12.ml1}>ATLANTIC - CARRIER</div>
      </div>
      <div className={style12.overlaybox}>
        <div className={style12.box}>
          <h4>ATLANTIC - CARRIER</h4>
          <ul>
            <li>☆ Automatic technology with geared machine</li>
            <li>☆ Best ride comfort</li>
            <li>☆ 2 hours fire-rated center opening doors</li>
            <li>☆ Full beam safety curtain</li>
            <li>☆ EN 81:1 safety compliance</li>
            <li>☆ Stainless steel and M.S. Powder coated cabins</li>
          </ul>
        </div>
        <div className={style12.box}>
          <img src={LIIM4} alt="LIIM1" className={style12.boximage} />
        </div>
      </div>
      <div className={style12.ml}>
        <div className={style12.ml1}>ATLANTIC - HOME LIFT</div>
      </div>
      <div className={style12.overlaybox}>
        <div className={style12.box}>
          <h4>ATLANTIC - HOME LIFT</h4>
          <ul>
            <li>☆ Automatic technology with geared machine</li>
            <li>☆ Best ride comfort</li>
            <li>☆ 2 hours fire-rated center opening doors</li>
            <li>☆ Full beam safety curtain</li>
            <li>☆ EN 81:1 safety compliance</li>
            <li>☆ Stainless steel and M.S. Powder coated cabins</li>
          </ul>
        </div>
        <div className={style12.box}>
          <img src={LIIM5} alt="LIIM1" className={style12.boximage} />
        </div>
      </div>
      <div className={style12.ml}>
        <div className={style12.ml1}>ATLANTIC - COMPACT</div>
      </div>
      <div className={style12.overlaybox}>
        <div className={style12.box}>
          <h4>ATLANTIC - COMPACT</h4>
          <ul>
            <li>☆ Compact utilization of space area.</li>
            <li>☆ Automatic technology with hydraulic power pack systems.</li>
            <li>
              ☆ Stainless steel cabin, Glass cabin and M.S. Powder coated cabin.
            </li>
            <li>☆ Best ride comfort with automatic rescue device</li>
            <li>☆ Full beam safety curtain.</li>
            <li>☆ EN 81:1 safety compliance</li>
          </ul>
        </div>
        <div className={style12.box}>
          <img src={LIIM6} alt="LIIM1" className={style12.boximage} />
        </div>
      </div>
      <div className={style12.ml}>
        <div className={style12.ml1}>ATLANTIC - MANUAL LIFT</div>
      </div>
      <div className={style12.overlaybox}>
        <div className={style12.box}>
          <h4>ATLANTIC - MANUAL LIFT</h4>
          <ul>
            <li>☆ Compact utilization of space area.</li>
            <li>☆ Automatic technology with hydraulic power pack systems.</li>
            <li>
              ☆ Stainless steel cabin, Glass cabin and M.S. Powder coated cabin.
            </li>
            <li>☆ Best ride comfort with automatic rescue device</li>
            <li>☆ Full beam safety curtain.</li>
            <li>☆ EN 81:1 safety compliance</li>
          </ul>
        </div>
        <div className={style12.box}>
          <img src={LIIM7} alt="LIIM1" className={style12.boximage} />
        </div>
      </div>
      <div className={style12.ml}>
        <div className={style12.ml1}>ADDITIONAL IMAGES</div>
      </div>
      <div className={style12.imagerow}>
        <img src={LIIM11} alt="TT1" className={style12.ttimage} />
        <img src={LIIM12} alt="TT2" className={style12.ttimage} />
        <img src={LIIM13} alt="TT3" className={style12.ttimage} />
      </div>
      <div className={style12.imagerow}>
        <img src={LIIM14} alt="TT4" className={style12.ttimage} />
        <img src={LIIM15} alt="TT5" className={style12.ttimage} />
      </div>
    </div>
  );
};

export default Lifts;
