import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import A from '../src/Images/A2.png';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="navbar">
      <div className="navbar-logo">
        <NavLink to="/" onClick={closeMobileMenu}>
          <img src={A} alt="Atlantic Logo" />
        </NavLink>
      </div>
      <nav className={`navbar-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <ul>
          <li className="dropdown">
            <button className="buttoni">Home &nbsp; <span className="icon">▼</span></button>
            <div className="dropdown-content">
              <NavLink to="/home1" onClick={closeMobileMenu}>Main</NavLink>
              <NavLink to="/home" onClick={closeMobileMenu}>Updates</NavLink>
            </div>
          </li>
          <li className="dropdown">
            <button className="buttoni">About Us &nbsp; <span className="icon">▼</span></button>
            <div className="dropdown-content">
              <NavLink to="/about" onClick={closeMobileMenu}>Our Story</NavLink>
            </div>
          </li>
          <li className="dropdown">
            <button className="buttoni">Product &nbsp; <span className="icon">▼</span></button>
            <div className="dropdown-content">
              <NavLink to="/Lifts" onClick={closeMobileMenu}>Keep source formatting</NavLink>
              <NavLink to="/Lifts/" onClick={closeMobileMenu}>Merge formatting</NavLink>
            </div>
          </li>
          <li className="dropdown">
            <button className="buttoni">Modernization &nbsp; <span className="icon">▼</span></button>
            <div className="dropdown-content">
              <NavLink to="/Modernization" onClick={closeMobileMenu}>Services & Maintenance</NavLink>
            </div>
          </li>
          <li className="dropdown">
            <button className="buttoni">Gallery &nbsp; <span className="icon">▼</span></button>
            <div className="dropdown-content">
              <NavLink to="/Gallery" onClick={closeMobileMenu}>Photos</NavLink>
              <NavLink to="/Mashin" onClick={closeMobileMenu}>Machine</NavLink>
            </div>
          </li>
          <li className="dropdown">
            <button className="buttoni">Career &nbsp; <span className="icon">▼</span></button>
            <div className="dropdown-content">
              <NavLink to="/Carrer" onClick={closeMobileMenu}>Opportunities</NavLink>
              <NavLink to="/Apply" onClick={closeMobileMenu}>Apply Now</NavLink>
            </div>
          </li>
          <li className="dropdown">
            <button className="buttoni">Contact Us &nbsp; <span className="icon">▼</span></button>
            <div className="dropdown-content">
              <NavLink to="/Contact" onClick={closeMobileMenu}>Reach Out</NavLink>
              <NavLink to="/Help" onClick={closeMobileMenu}>Help</NavLink>
            </div>
          </li>
        </ul>
      </nav>
      <button
        className="hamburger"
        onClick={toggleMobileMenu}
        aria-label="Toggle Mobile Menu"
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>
    </header>
  );
};

export default Navbar;
