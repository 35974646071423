import React from "react";
import style6 from "./Footer.module.css";
import logo from "./Images/A2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faInfoCircle,
  faArrowUp,
  faCogs,
  faConciergeBell,
  faImages,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom"; // Import NavLink

const Footer = () => {
  return (
    <div className={style6.footer}>
      <div className={style6.sbfootersectionpadding}>
        <div className={style6.sbfooterlinks}>
          <div className={style6.sbfooterlinksdiv}>
            <img src={logo} alt="Atlantic Lifts" />
            <NavLink to="/about">
              <p>
                Atlantic Lifts offers all types of vertical transit services
                including passenger lifts and freight lifts.
              </p>
            </NavLink>
            <NavLink to="/about">
              <p>Read More...</p>
            </NavLink>
          </div>

          <div className={style6.sbfooterlinksdiv}>
            <h4>Quick Links</h4>
            <NavLink to="/home1">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faHome} /> Home
              </p>
            </NavLink>
            <NavLink to="/about">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faInfoCircle} />{" "}
                About us
              </p>
            </NavLink>
            <NavLink to="/lifts">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faArrowUp} />{" "}
                Our Lifts
              </p>
            </NavLink>
            <NavLink to="/Modernization">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faCogs} />{" "}
                Modernization
              </p>
            </NavLink>
            <NavLink to="/Modernization">
              <p>
                <FontAwesomeIcon
                  className={style6.iconspacing}
                  icon={faConciergeBell}
                />{" "}
                Service
              </p>
            </NavLink>
            <NavLink to="/Gallery">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faImages} />{" "}
                Gallery
              </p>
            </NavLink>
            <NavLink to="/Contact">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faEnvelope} />{" "}
                Contact us
              </p>
            </NavLink>
          </div>

          <div className={style6.sbfooterlinksdiv}>
            <h4>Contact Info</h4>
            <a
              href="https://www.google.com/maps/search/?api=1&query=Atlantic+Lifts+India+Pvt+Ltd,+Manik+Moti,+D-2+Building,+Office+No+7,+Beside+Kanchan+HP+Gas+Agency,+More+Bagh,+Katraj,+Pune+411046"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                <FontAwesomeIcon
                  className={style6.iconspacing}
                  icon={faMapMarkerAlt}
                />
                Atlantic Lifts India Pvt Ltd. Manik Moti, D-2 Building, Office
                No. 7, Beside Kanchan HP Gas Agency, More Bagh, Katraj, Pune
                411046
              </p>
            </a>
            <a href="tel:9008629008">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faPhone} />
                9008629008
              </p>
            </a>
            <a href="tel:9158878899">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faPhone} />
                9158878899
              </p>
            </a>
            <a href="tel:02024378895">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faPhone} />
                (020) 243 78 895
              </p>
            </a>
            <a href="mailto:customercare@atlanticlifts.in">
              <p>
                <FontAwesomeIcon className={style6.iconspacing} icon={faEnvelope} />
                customercare@atlanticlifts.in
              </p>
            </a>
          </div>

          <div className={style6.sbfooterlinksdiv}>
            <h4>Head Office</h4>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.7554815044205!2d73.85748699999999!3d18.449408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eac4cd05480b%3A0x4d564b47537bfbf4!2sAtlantic%20Lifts%20India%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1728293525362!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps location for Atlantic Lifts India Pvt. Ltd."
            ></iframe>
          </div>
        </div>

        <hr className={style6.hr} />

        <div className={style6.sbfooterbelow}>
          <div className={style6.sbfootercopyright}>
            <p>
              Copyright @{new Date().getFullYear()} Elevator Manufacturer in
              Pune | Atlantic Lifts India Pvt. Ltd
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
