import React, { useState } from 'react';
import './help.css'; // Link to the Help.css file
import backgroundVideo from './Images/855191-hd_1920_1080_25fps.mp4';

const Help = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Placeholder for form submission logic
    alert('Thank you! Your form has been submitted.');
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div>
      <div className="image-container">
        <video autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <div className="text-overlay">
          <h2>Need Assistance?</h2>
          <h6 className="fo">We're here to help!</h6>
        </div>
      </div>
      <div className="help-container" id="help-form">
        <span className="help-header">Contact Us</span>
        <form className="help-form" onSubmit={handleSubmit}>
          <label htmlFor="name" className="label">Full Name</label>
          <input
            required
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input-field"
            placeholder="Enter your name"
          />

          <label htmlFor="email" className="label">Email Address</label>
          <input
            required
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="input-field"
            placeholder="Enter your email"
          />

          <label htmlFor="message" className="label">Message</label>
          <textarea
            required
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="textarea-field"
            placeholder="How can we assist you?"
          />

          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Help;
