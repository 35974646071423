import React from 'react'
import style01 from './Apply.module.css'
import img from './Images/tre3.jpg'

const Apply = () => {
    
  return (
    <>
    <div className={style01.main_conatiner}>
    <div className={style01.image_container}>
        <img src={img} alt=''></img>
    <div className={style01.text_overlay}>
        <h2>CAREERS</h2>
    <h6 className={style01.fo}>Always looking for enthusiastic Candidate</h6>
    </div>
    </div>
    </div>
    <div className={style01.container}>
      <h2 className={style01.heading}>Job Application Form</h2>

      <form className={style01.form}>
        <input
          type="text"
          className={style01.input}
          placeholder="Full Name"
        />
        <input
          type="email"
          className={style01.input}
          placeholder="Email"
        />
        <input
          type="text"
          className={style01.input}
          placeholder="Phone Number"
        />
        <input
          type="text"
          className={style01.input}
          placeholder="LinkedIn Profile URL"
        />
        <textarea
          name="cover_letter"
          className={style01.textarea}
          placeholder="Cover Letter"
        ></textarea>
        <input
          type="file"
          className={style01.file_input}
        />

        <button
          type="submit"
          className={style01.button}
        >
          Apply
        </button>
      </form>
    </div>
    </>
  
)
}

export default Apply